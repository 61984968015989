html {
  background-color: #dfdfe2;
  font-size: 16px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box !important;
}

body {
  margin: 0;
}

input {
  font-size: inherit;
  color: inherit;
}

@font-face {
  font-family: 'metropolislight';
  src: url('../../../../public/fonts/metropolis-light-webfont.woff2') format('woff2'),
        url('../../../../public/fonts/metropolis-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: 'metropolismedium';
  src: url('../../../../public/fonts/metropolis-medium-webfont.woff2') format('woff2'),
        url('../../../../public/fonts/metropolis-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'metropolisthin';
  src: url('../../../../public/fonts/metropolis-thin-webfont.woff2') format('woff2'),
        url('../../../../public/fonts/metropolis-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'metropolisbold';
  src: url('../../../../public/fonts/metropolis-bold-webfont.woff2') format('woff2'),
        url('../../../../public/fonts/metropolis-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
